<template>
  <svg
    :width="size"
    :height="size"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 252 200"
    fill="none"
  >
    <path
      d="M96.7079 176.829C53.667 175.714 12.1309 156.592 2.48326 106.469C-11.2238 35.2531 79.1526 6.02312 134.457 1.17978C143.808 0.36088 153.112 2.38236 160.767 6.6397C175.671 14.9279 168.58 26.8206 158.603 35.2531C153.305 39.7315 148.471 44.6849 145.336 50.2773C142.393 55.5266 143.104 60.394 151.26 61.6541C157.066 62.5511 164.308 59.6963 169.501 57.649C169.638 57.595 169.774 57.5415 169.908 57.4886C170.407 57.2921 170.907 57.0944 171.409 56.8963C183.277 52.207 195.826 47.2489 209.363 50.6524C223.072 54.0993 223.83 64.5159 218.699 73.1237C217.768 74.6857 216.724 76.328 215.645 78.0248C211.613 84.3636 207.098 91.4627 206.204 97.9759C204.933 107.232 220.807 108.969 235.433 110.571C249.369 112.096 251.803 113.999 251.803 121.16C251.803 159.522 139.749 177.944 96.7079 176.829Z"
      fill="#F9F8FF"
    />
    <path
      d="M57.9167 37.0239C57.9167 31.5011 62.3938 27.0239 67.9167 27.0239H117.329C120.096 27.0239 122.74 28.1708 124.631 30.1916L143.552 50.4134C145.286 52.2661 146.25 54.7085 146.25 57.2458V136.191C146.25 141.713 141.773 146.191 136.25 146.191H67.9167C62.3938 146.191 57.9167 141.713 57.9167 136.191V37.0239Z"
      stroke="#0B0B0B"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-dasharray="8.33 9.17"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.9076 1.75624C40.1273 0.829059 41.7315 1.13923 42.6033 2.10932L50.6983 15.1751C51.2443 15.9161 51.0862 16.9595 50.3451 17.5055C49.6041 18.0515 48.5607 17.8934 48.0147 17.1524L38.1354 5.40095C37.5511 3.37054 38.1666 2.30225 38.9076 1.75624Z"
      fill="#6366FA"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M179.254 102.465C179.513 103.975 178.496 105.255 177.233 105.58L161.905 106.721C160.996 106.863 160.144 106.241 160.002 105.331C159.86 104.422 160.482 103.57 161.392 103.428L176.378 100.096C178.449 100.516 179.112 101.556 179.254 102.465Z"
      fill="#6366FA"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.3938 24.7417C28.708 23.4644 29.9551 22.7732 31.0698 22.9158L43.6328 26.8626C44.4037 27.0381 44.8663 27.8005 44.6659 28.5654C44.4656 29.3302 43.6783 29.8079 42.9073 29.6324L29.8618 27.527C28.3622 26.5392 28.1934 25.5066 28.3938 24.7417Z"
      fill="#6366FA"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M169.604 85.6989C170.45 86.7059 170.273 88.1207 169.501 88.9369L158.905 96.7553C158.308 97.2744 157.42 97.1984 156.92 96.5856C156.421 95.9727 156.499 95.0551 157.095 94.536L166.489 85.2419C168.171 84.6134 169.105 85.0861 169.604 85.6989Z"
      fill="#6366FA"
    />
    <rect
      x="145.889"
      y="144.589"
      width="8.00781"
      height="9.00878"
      transform="rotate(-45 145.889 144.589)"
      fill="#0B0C63"
    />
    <path
      d="M148.012 152.375L159.337 141.05L184.35 166.063C186.954 168.667 186.954 172.888 184.35 175.492L182.454 177.388C179.85 179.992 175.629 179.992 173.025 177.388L148.012 152.375Z"
      fill="#2B2D97"
    />
    <path
      d="M149.782 103.183C160.922 114.324 160.922 132.387 149.782 143.527C138.641 154.668 120.578 154.668 109.437 143.527C98.2963 132.387 98.2963 114.324 109.437 103.183C120.578 92.0422 138.641 92.0422 149.782 103.183Z"
      fill="#2B2D97"
    />
    <circle
      cx="129.609"
      cy="123.355"
      r="21.521"
      fill="#EAFAFF"
    />
    <path
      d="M161.46 153.791L150.136 154.498L148.72 160.161L153.675 165.823L161.106 169.716L164.999 169.362L165.707 168.654L165.707 165.823L164.999 163.7L162.168 165.115L165.353 162.638L164.292 160.161L162.168 160.869L155.798 160.161L161.46 157.33L161.46 153.791Z"
      fill="#DBC2B1"
    />
    <path
      d="M173.139 151.313L175.617 157.329L161.815 143.527L163.584 142.466L167.477 147.066L173.139 151.313Z"
      fill="#C9AC98"
    />
    <path
      d="M109.029 129.669L124.786 102.377C126.336 102.022 127.951 101.834 129.609 101.834C131.141 101.834 132.636 101.994 134.077 102.299L113.61 137.749C111.54 135.45 109.958 132.702 109.029 129.669Z"
      fill="white"
    />
    <path
      d="M115.839 139.894L137.051 103.155C138.318 103.622 139.529 104.205 140.672 104.891L119.15 142.167C117.974 141.512 116.866 140.75 115.839 139.894Z"
      fill="white"
    />
    <path
      d="M181.355 165.514L159.334 143.993L157.332 145.995L179.353 167.515L181.355 165.514Z"
      fill="#8E91FF"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcChevron',
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
}
</script>
