<template>
  <div class="text-center my-auto">
    <svg
      :width="size"
      :height="size"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="animate-spin mx-auto"
    >
      <g clip-path="url(#clip0_2365_34810)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.9998 13.3333C32.9274 13.3333 26.1446 16.1428 21.1437 21.1438C16.1427 26.1448 13.3332 32.9275 13.3332 40C13.3332 47.0724 16.1427 53.8552 21.1437 58.8562C26.1446 63.8571 32.9274 66.6667 39.9998 66.6667C47.0723 66.6667 53.855 63.8571 58.856 58.8562C63.857 53.8552 66.6665 47.0724 66.6665 40C66.6665 32.9275 63.857 26.1448 58.856 21.1438C53.855 16.1428 47.0723 13.3333 39.9998 13.3333ZM6.6665 40C6.6665 21.59 21.5898 6.66666 39.9998 6.66666C58.4098 6.66666 73.3332 21.59 73.3332 40C73.3332 58.41 58.4098 73.3333 39.9998 73.3333C21.5898 73.3333 6.6665 58.41 6.6665 40Z"
          fill="#F4F2FF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M40.0001 13.3333C33.1248 13.3188 26.5124 15.9743 21.5568 20.74C21.2407 21.0429 20.8681 21.2806 20.4602 21.4395C20.0523 21.5984 19.617 21.6755 19.1794 21.6662C18.7417 21.6569 18.3101 21.5615 17.9093 21.3854C17.5085 21.2093 17.1463 20.9561 16.8434 20.64C16.5405 20.324 16.3028 19.9513 16.1439 19.5434C15.985 19.1355 15.908 18.7003 15.9173 18.2626C15.9265 17.825 16.0219 17.3934 16.198 16.9926C16.3741 16.5918 16.6274 16.2296 16.9434 15.9267C23.1403 9.97144 31.4055 6.65198 40.0001 6.66667C40.8841 6.66667 41.732 7.01786 42.3571 7.64299C42.9822 8.26811 43.3334 9.11595 43.3334 10C43.3334 10.8841 42.9822 11.7319 42.3571 12.357C41.732 12.9822 40.8841 13.3333 40.0001 13.3333Z"
          fill="#6366FA"
        />
      </g>
      <defs>
        <clipPath id="clip0_2365_34810">
          <rect
            width="80"
            height="80"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>

    <div class="mt-4">
      <slot>
        <p>Loading...</p>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrightbidLoader',
  props: {
    size: {
      type: [String, Number],
      default: 100,
    },
  },
}
</script>

<style scoped></style>
