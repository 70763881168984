<template>
  <page class="main-container base-scrollbar">
    <div
      v-if="isLoading"
      class="h-full flex items-center justify-center"
    >
      <brightbid-loader size="120" />
    </div>
    <div
      v-else
      class="w-full h-full"
    >
      <iframe
        v-if="showReports"
        :src="siteDetails.embed_url"
        class="w-full h-full border-0"
        frameborder="0"
        allowfullscreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      >
      </iframe>
      <div
        v-else
        class="w-full h-full text-center flex flex-col justify-center items-center"
      >
        <IcFileSearch :size="250" />
        <p class="text-xl font-medium">Oh no, the reports has not been added yet!</p>
        <div v-if="standardUser">
          <p class="text-sm">
            Use the <b>support centre</b> in the right bottom corner to get in touch with us <br />and we will set up
            the report for you.
          </p>
        </div>
        <div v-if="!standardUser">
          <p class="text-sm">
            Go to
            <router-link :to="{ name: 'site:settings:site' }"
              ><b class="text-bb-brand-purple">Site Settings</b></router-link
            >
            to add a report.
          </p>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/base/page/Page.vue'
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import IcFileSearch from '@/components/icon/ic-file-search.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ReportsIndex',
  components: { Page, IcFileSearch, BrightbidLoader },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('site', ['selectedSite', 'siteDetails']),
    standardUser() {
      return this.user.role.name === 'standard'
    },
    showReports() {
      return this?.siteDetails?.embed_url ? true : false
    },
  },
  methods: {
    ...mapActions('site', ['fetchSiteDetails']),
  },
  watch: {
    async selectedSite(newVal, oldVal) {
      if (newVal && newVal.value !== oldVal?.value) {
        this.pageLoading = true
        await this.fetchSiteDetails(newVal.value)
        this.pageLoading = false
      }
    },
  },
  async mounted() {
    if (this.selectedSite && this?.siteDetails?.id !== this.selectedSite.value) {
      this.isLoading = true
      await this.fetchSiteDetails(this.selectedSite.value)
      this.isLoading = false
    }
  },
}
</script>

<style lang="scss">
.main-container {
  overflow-y: auto;
  height: calc(100vh - 103px);
}
</style>
